/* import __COLOCATED_TEMPLATE__ from './standalone-access-level-selector.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Admin from 'embercom/models/admin';

interface Signature {
  Args: {
    column: {
      widthStyle: number;
    };
    row: {
      teammate: Admin;
      isCurrentAdmin: boolean;
      roles: any;
    };
    tableActions: {};
  };
}

/* eslint-disable-next-line @intercom/intercom/no-bare-strings */
export const FULL_ACCESS_ROLE_NAME = 'Full Access';

export default class StandaloneAccessLevelSelector extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare permissionsMutatorService: $TSFixMe;

  get teammate() {
    return this.args.row.teammate;
  }

  get accessLevelItems() {
    return this.args.row.roles.map((role: any) => ({
      text: role.name,
      value: role.id,
    }));
  }

  get currentAccessLevelValue() {
    return this.teammate.currentAppPermissions.role?.id || this.fullAccessRole.id;
  }

  get currentAccessLevel() {
    return this.teammate.currentAppPermissions.role?.name || FULL_ACCESS_ROLE_NAME;
  }

  get fullAccessRole() {
    return this.args.row.roles.find((role: any) => role.name === FULL_ACCESS_ROLE_NAME);
  }

  @action async onAccessLevelChange(value: string) {
    if (this.teammate.currentAppPermissions.role_id !== value) {
      this.teammate.currentAppPermissions.role_id = value;

      await this.permissionsMutatorService.savePermission(
        this.teammate,
        this.teammate.currentAppPermissions,
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::TeammateList::StandaloneAccessLevelSelector': typeof StandaloneAccessLevelSelector;
    'table-cells/teammate-list/standalone-access-level-selector': typeof StandaloneAccessLevelSelector;
  }
}
